import classNames from 'classnames'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Spinner from '../../../Spinner'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '../../../core/Listbox'
import { TimeFilterContext } from './TimeSelectorProvider'

export default function TimeSelector() {
  const { t } = useTranslation(['dashboard'])

  const context = useContext(TimeFilterContext)

  const {
    year,
    periodType,
    startPeriod,
    endPeriod,
    setYear,
    setPeriodType,
    setStartPeriod,
    setEndPeriod,
    timeFilters,
    periodTypes,
    periods,
    isLoading,
  } = context

  if (isLoading || !year) {
    return <Spinner />
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-5">
        <Listbox value={year} onChange={setYear}>
          {({ open }) => (
            <>
              <div className="relative mt-1">
                <ListboxLabel>
                  {t('timeFilters.selector.yearLabel')}
                </ListboxLabel>
                <ListboxButton>{year}</ListboxButton>
                <ListboxOptions isOpen={open}>
                  {timeFilters.map(({ name }) => (
                    <ListboxOption key={name} value={name}>
                      {name}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </>
          )}
        </Listbox>
        <Listbox value={periodType} onChange={setPeriodType}>
          {({ open }) => (
            <>
              <div className="relative mt-1">
                <ListboxLabel>
                  {t('timeFilters.selector.periodLabel')}
                </ListboxLabel>
                <ListboxButton>{periodTypes[periodType]}</ListboxButton>
                <ListboxOptions isOpen={open}>
                  {Object.entries(periodTypes).map(([key, value]) => (
                    <ListboxOption key={key} value={key}>
                      {value}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </>
          )}
        </Listbox>
        <Listbox value={startPeriod} onChange={setStartPeriod}>
          {({ open }) => (
            <>
              <div
                className={classNames('relative', 'mt-1', {
                  hidden: periods.length === 0,
                })}
              >
                <ListboxLabel>
                  {t('timeFilters.selector.startPeriodLabel')}
                </ListboxLabel>
                <ListboxButton>
                  {startPeriod !== null ? periods[startPeriod] : '…'}
                </ListboxButton>
                <ListboxOptions isOpen={open}>
                  {periods.map((period, index) => (
                    <ListboxOption key={period} value={index}>
                      {period}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </>
          )}
        </Listbox>
        <Listbox value={endPeriod} onChange={setEndPeriod}>
          {({ open }) => (
            <>
              <div
                className={classNames('relative', 'mt-1', {
                  hidden: periods.length === 0,
                })}
              >
                <ListboxLabel>
                  {t('timeFilters.selector.endPeriodLabel')}
                </ListboxLabel>
                <ListboxButton>
                  {startPeriod !== null ? periods[endPeriod] : '…'}
                </ListboxButton>
                <ListboxOptions isOpen={open}>
                  {periods.map((period, index) => (
                    <ListboxOption
                      key={period}
                      value={index}
                      disabled={periods.indexOf(period) < startPeriod}
                    >
                      {period}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </>
  )
}
