import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import BuildingPopover from '../components/BuildingPopover'
import BuildingFilterProvider, {
  useBuildingFilters,
} from '../components/BuildingPopover/BuildingFilterProvider'
import { GridCard } from '../components/Card/Card'
import CategoryLabel from '../components/CategoryLabel/CategoryLabel'
import CategoryFilterProvider, {
  useCategoryFilters,
} from '../components/CategoryPopover/CategoryFilterProvider'
import CategoryPopover from '../components/CategoryPopover/CategoryPopover'
import Checked from '../components/Checked'
import { Header, Page, Section } from '../components/Page/Page'
import PaginationNav from '../components/PaginationNav/PaginationNav'
import Table from '../components/Table/Table'
import TimeRangeFilterProvider, {
  useTimeRangeFilters,
} from '../components/TimeRangePopover/TimeRangeFilterProvider'
import TimeRangePopover from '../components/TimeRangePopover/TimeRangePopover'
import FormattedNumber from '../components/core/FormattedNumber/FormattedNumber'
import { InvoiceFilter, getInvoices } from '../loaders/vendors'
import { Building, Category, VendorInvoice } from '../models'
import { formatDate } from '../utils/format'

const PAGE_SIZE = 20

const InvoiceList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['dashboard'])
  const [page, setPage] = useState(1)
  const filter = useFilter()
  const { includeChildCategories } = useCategoryFilters()

  const onSelected = (invoice: VendorInvoice) => {
    navigate(`/vendors/invoices/${invoice.id}`)
  }

  const { data } = useQuery({
    queryKey: ['invoices', page, filter, includeChildCategories],
    queryFn: () =>
      getInvoices({
        page,
        pageSize: PAGE_SIZE,
        filter,
        includeChildCategories,
      }),
  })

  const invoices = useMemo(() => data?.data ?? [], [data])
  const pagination = useMemo(() => data?.pagination, [data])

  return (
    <>
      <Table
        headerCells={[
          t('invoices.table.vendor'),
          t('invoices.table.number'),
          t('invoices.table.date'),
          t('invoices.table.category'),
          t('invoices.table.amount'),
        ]}
        rows={invoices.map((invoice) => ({
          data: invoice,
          cells: [
            invoice.vendor?.name,
            invoice.invoiceNumber ? `#${invoice.invoiceNumber}` : '-',
            formatDate(invoice.purchaseDate),
            invoice.category ? (
              <CategoryLabel category={invoice.category} showFull={false} />
            ) : (
              '-'
            ),
            <FormattedNumber
              key="amount"
              value={invoice.amount}
              isCurrency={true}
            />,
          ],
        }))}
        onRowClick={(invoice) => onSelected(invoice)}
      />
      {pagination && pagination.pageCount > 1 && (
        <PaginationNav
          pagination={pagination}
          onPrevious={() => setPage(pagination?.previousPage ?? 1)}
          onNext={() => setPage(pagination?.nextPage ?? 1)}
        />
      )}
    </>
  )
}

const useFilter = () => {
  const [searchParams] = useSearchParams()
  const [filter, setFilter] = useState<InvoiceFilter>({})

  // Set initial filters based on query params
  useEffect(() => {
    const categoryId = searchParams.get('categoryId')
    const buildingId = searchParams.get('buildingId')
    const from = searchParams.get('from')
    const to = searchParams.get('to')
    const newFilter: InvoiceFilter = {}

    if (categoryId) {
      newFilter.categoryId = parseInt(categoryId)
    }

    if (buildingId) {
      newFilter.buildingId = parseInt(buildingId)
    }

    if (from || to) {
      newFilter.purchaseDate = {
        ...(from && { gte: from }),
        ...(to && { lte: to }),
      }
    }

    setFilter(newFilter)
  }, [searchParams])

  return filter
}

const Filters = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { buildings, setBuilding } = useBuildingFilters()
  const { categories, setCategory, setIncludeChildCategories } =
    useCategoryFilters()
  const { setTimeRange } = useTimeRangeFilters()

  const filter = useFilter()

  // Set initial filters based on query params
  useEffect(() => {
    if (filter.buildingId) {
      const filterBuilding =
        buildings?.find((b) => b.id === filter.buildingId) ?? null

      if (filterBuilding) {
        setBuilding(filterBuilding)
      }
    }

    if (filter.categoryId) {
      const filterCategory =
        categories?.find((c) => c.id === filter.categoryId) ?? null
      if (filterCategory) {
        setCategory(filterCategory)
      }
    }

    if (filter.purchaseDate?.gte && filter.purchaseDate?.lte) {
      setTimeRange(
        moment.utc(filter.purchaseDate.gte).toDate(),
        moment.utc(filter.purchaseDate.lte).toDate()
      )
    }
  }, [buildings, categories, filter])

  // Set initial includeChildCategories based on query param
  useEffect(() => {
    const queryIncludeChildCategories = searchParams.get(
      'includeChildCategories'
    )
    if (queryIncludeChildCategories != null) {
      setIncludeChildCategories(queryIncludeChildCategories === 'true')
    }
  }, [searchParams])

  const onBuildingChange = (building?: Building) => {
    setBuilding(building)
    if (building) {
      searchParams.set('buildingId', building.id.toString())
    } else {
      searchParams.delete('buildingId')
    }
    setSearchParams(searchParams)
  }

  const onCategoryChange = (
    category?: Category,
    includeChildCategories?: boolean
  ) => {
    if (category) {
      searchParams.set('categoryId', category.id.toString())
    } else {
      searchParams.delete('categoryId')
    }
    if (includeChildCategories != null) {
      searchParams.set(
        'includeChildCategories',
        includeChildCategories.toString()
      )
    } else {
      searchParams.delete('includeChildCategories')
    }
    setSearchParams(searchParams)
  }

  const onTimeRangeChange = (fromDate: Date | null, toDate: Date | null) => {
    if (fromDate && toDate) {
      searchParams.set('from', moment.utc(fromDate).format('YYYY-MM-DD'))
      searchParams.set('to', moment.utc(toDate).format('YYYY-MM-DD'))
    } else {
      searchParams.delete('from')
      searchParams.delete('to')
    }
    setSearchParams(searchParams)
  }

  return (
    <div className="flex flex-row gap-x-4 justify-end mb-4">
      <BuildingPopover onChange={onBuildingChange} required={false} />
      <CategoryPopover onChange={onCategoryChange} />
      <TimeRangePopover onChange={onTimeRangeChange} />
    </div>
  )
}

const Invoices = () => {
  const { t } = useTranslation(['dashboard'])

  return (
    <BuildingFilterProvider>
      <CategoryFilterProvider>
        <TimeRangeFilterProvider>
          <Checked right="vendor:read">
            <Page>
              <Header subtitle={t('invoices.subtitle') ?? ''}>
                {t('invoices.title')}
              </Header>
              <Section>
                <Filters />
                <GridCard>
                  <InvoiceList />
                </GridCard>
              </Section>
            </Page>
          </Checked>
        </TimeRangeFilterProvider>
      </CategoryFilterProvider>
    </BuildingFilterProvider>
  )
}

export default Invoices
