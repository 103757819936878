import { ClockIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatIntervalRange } from '../../utils/format'
import Popover, { PopoverSection, PopoverSections } from '../core/Popover'
import { useTimeRangeFilters } from './TimeRangeFilterProvider'
import TimeRangePicker, { PeriodType } from './TimeRangePicker'

interface TimePopoverProps {
  defaultToLast?: boolean
  onChange?: (fromDate: Date | null, toDate: Date | null) => void
}

const TimeRangePopover = ({ defaultToLast, onChange }: TimePopoverProps) => {
  const { t } = useTranslation(['dashboard'])

  const [label, setLabel] = useState('')
  const { fromDate, toDate, setTimeRange } = useTimeRangeFilters()
  const [periodType] = useState<PeriodType>('month')

  // Show last period if no date was provided
  useEffect(() => {
    if (!fromDate && !toDate && defaultToLast) {
      const lastPeriod = moment.utc().subtract(1, periodType)
      setTimeRange(
        lastPeriod.startOf(periodType).toDate(),
        lastPeriod.endOf(periodType).toDate()
      )
    }
  }, [])

  // Update label when fromDate or toDate changes
  useEffect(() => {
    const from = moment.utc(fromDate)
    const to = moment.utc(toDate)

    if (from.isValid() && to.isValid()) {
      setLabel(formatIntervalRange(fromDate, toDate, periodType))
    } else {
      setLabel(t('filter.time.all'))
    }
  }, [fromDate, toDate])

  const onRangeChange = useCallback(
    (fromDate: Date | null, toDate: Date | null) => {
      setTimeRange(fromDate, toDate)
      onChange?.(fromDate, toDate)
    },
    [fromDate, toDate, onChange]
  )

  return (
    <Popover
      text={label}
      icon={<ClockIcon aria-hidden="true" className="h-5 w-5" />}
      hasValue={!!fromDate && !!toDate}
    >
      <PopoverSections>
        <PopoverSection>
          <TimeRangePicker
            periodType={periodType}
            fromDate={fromDate}
            toDate={toDate}
            onChange={onRangeChange}
          />
        </PopoverSection>
      </PopoverSections>
    </Popover>
  )
}

export default TimeRangePopover
