interface UseDownloadProps {
  readonly downloadFn: () => Promise<Blob>
  readonly getFileName: () => string
  readonly onError?: (error: Error) => void
}

type StartDownloadFn = () => Promise<void>

export const useDownloadFile = ({
  downloadFn,
  getFileName,
  onError,
}: UseDownloadProps): StartDownloadFn => {
  return async () => {
    try {
      const data = await downloadFn()
      const url = URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.download = getFileName()
      link.dispatchEvent(new MouseEvent('click'))
      URL.revokeObjectURL(url)
    } catch (error) {
      onError?.(error as Error)
    }
  }
}
