import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { ComponentPropsWithoutRef, useMemo } from 'react'

import { Category } from '../../models'
import { getCategoryComponents } from '../../utils/category'

interface CategoryLabelProps extends ComponentPropsWithoutRef<'div'> {
  category: Category
  showFull?: boolean
}

const CategoryLabel = ({
  category,
  showFull = true,
  ...props
}: CategoryLabelProps) => {
  const components = useMemo(() => {
    if (showFull) {
      return getCategoryComponents(category)
    } else {
      return [category.shortName]
    }
  }, [category, showFull])

  return (
    <div
      className="inline-flex flex-row items-center gap-1 bg-brand-yellow-light text-gray-700 rounded-full px-2 py-1 text-xs font-medium"
      {...props}
    >
      {components.map((path, index) => (
        <div key={index} className="flex flex-row items-center">
          <span>{path}</span>
          {index < components.length - 1 && (
            <ChevronRightIcon
              aria-hidden="true"
              className="size-3 shrink-0 ml-1 text-gray-400"
            />
          )}
        </div>
      ))}
    </div>
  )
}

export default CategoryLabel
