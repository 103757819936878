import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  EllipsisHorizontalIcon,
  ExclamationCircleIcon,
  NoSymbolIcon,
  SparklesIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import AISidebar, {
  AIDisclosureSection,
} from '../../components/AISidebar/AISidebar'
import Spinner from '../../components/Spinner'
import { Button } from '../../components/core/Button/Button'
import { useDrawer } from '../../components/core/Drawer/DrawerProvider'
import Markdown from '../../components/core/Markdown/Markdown'
import { useDownloadFile } from '../../hooks/useDownloadFile'
import { downloadFile, processDocument } from '../../loaders/documents'
import {
  deleteInvoice as deleteVendorInvoice,
  getInvoiceAnalysis,
} from '../../loaders/vendors'
import { InvoiceAnalysis, VendorInvoice } from '../../models'

type RecommendationType = 'accept' | 'reject' | 'investigate'

const recommendationIcon: Record<RecommendationType, React.ReactNode> = {
  accept: <CheckCircleIcon className="h-8 w-auto text-brand-600" />,
  reject: <NoSymbolIcon className="h-8 w-auto text-red-600" />,
  investigate: <ExclamationCircleIcon className="h-8 w-auto text-yellow-400" />,
}

interface SummaryProps extends ComponentPropsWithoutRef<'div'> {
  text?: string
  type?: RecommendationType
}

const Summary = ({ text, type }: SummaryProps) => {
  return (
    <div className="flex flex-col items-center gap-2 p-3 bg-white text-gray-600 text-sm rounded-lg">
      {type && (
        <div className="w-full flex justify-center my-2">
          {recommendationIcon[type]}
        </div>
      )}
      {text && <Markdown>{text}</Markdown>}
    </div>
  )
}

interface AnalysisProps {
  analysis: InvoiceAnalysis
}

const Analysis = ({ analysis }: AnalysisProps) => {
  const { t } = useTranslation(['dashboard'])
  return (
    <div className="space-y-4 animate-fadeIInTranslateY">
      <Summary
        text={analysis?.summary}
        type={analysis?.recommendation as RecommendationType}
      />
      {analysis?.correctness && (
        <AIDisclosureSection
          title={t('invoice.ai.analysis.correctness')}
          text={analysis?.correctness}
        />
      )}
      {analysis?.accounting && (
        <AIDisclosureSection
          title={t('invoice.ai.analysis.accounting')}
          text={analysis.accounting}
        />
      )}
      {analysis?.agreements && (
        <AIDisclosureSection
          title={t('invoice.ai.analysis.agreements')}
          text={analysis?.agreements}
        />
      )}
      {analysis?.invoices && (
        <AIDisclosureSection
          title={t('invoice.ai.analysis.invoices')}
          text={analysis?.invoices}
        />
      )}
      {analysis?.payments && (
        <AIDisclosureSection
          title={t('invoice.ai.analysis.payments')}
          text={analysis?.payments}
        />
      )}
      {analysis?.budgets && (
        <AIDisclosureSection
          title={t('invoice.ai.analysis.budgets')}
          text={analysis.budgets}
        />
      )}
    </div>
  )
}

const stepLabels: string[] = [
  'invoice.ai.step.correctness',
  'invoice.ai.step.agreements',
  'invoice.ai.step.invoices',
  'invoice.ai.step.payments',
  'invoice.ai.step.budgets',
  'invoice.ai.step.accounting',
  'invoice.ai.step.summary',
]

interface ActionsProps {
  invoice?: VendorInvoice
}

const ActionMenu = ({ invoice }: ActionsProps) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { t } = useTranslation(['dashboard'])

  const { mutate: deleteInvoice, isLoading: isDeleting } = useMutation({
    mutationFn: (invoiceId: number) => deleteVendorInvoice(invoiceId),
    onSuccess: () => {
      navigate('/vendors/invoices')
      toast.success(t('invoice.delete.notification.success'))
    },
  })

  const { mutate: processInvoice, isLoading: isProcessing } = useMutation({
    mutationFn: (documentId: number) => processDocument(documentId),
    onSuccess: () => {
      // trigger re-render of invoice since its now been processed
      queryClient.invalidateQueries({ queryKey: ['invoice', invoice!.id] })
      toast.success(t('invoice.process.notification.success'))
    },
    onError: (error) => {
      toast.error(t('invoice.process.notification.error', { error }))
    },
  })

  const onDownload = useDownloadFile({
    downloadFn: () => downloadFile(invoice!.document!.id),
    getFileName: () => invoice!.document!.name,
    onError: (error) => {
      toast.error(t('download.notification.error', { error }))
    },
  })

  const links: {
    label: string
    onClick: () => void
    loading?: boolean
    icon: React.ElementType
  }[] = [
    {
      label: 'invoice.download',
      onClick: onDownload,
      icon: DocumentTextIcon,
    },
    {
      label: 'invoice.process.action',
      onClick: () => processInvoice(invoice!.document!.id),
      loading: isProcessing,
      icon: ArrowPathIcon,
    },
    {
      label: 'invoice.delete.action',
      onClick: () => deleteInvoice(invoice!.id),
      loading: isDeleting,
      icon: TrashIcon,
    },
  ]
  return (
    <Menu
      as="div"
      className="text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
    >
      <div>
        <MenuButton className="flex items-center justify-center rounded-full p-1.5 hover:bg-gray-200 text-gray-700">
          <EllipsisHorizontalIcon className="size-6" />
        </MenuButton>
      </div>
      <MenuItems
        transition
        anchor="top"
        className="z-50 mt-4 origin-bottom divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        {links.map((link, index) => (
          <MenuItem key={`action-${index}`}>
            <button
              onClick={link.onClick}
              disabled={link.loading}
              className="w-full group flex items-center px-3 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              {link.loading ? (
                <Spinner size="sm" />
              ) : (
                <link.icon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                />
              )}
              <span className="ml-2">{t(link.label)}</span>
            </button>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  )
}

const AnalysisButton = ({ invoice }: ActionsProps) => {
  const { showDrawer } = useDrawer()
  const { t } = useTranslation(['dashboard'])

  const [stepIndex, setStepIndex] = useState(0)
  const [analysis, setAnalysis] = useState<InvoiceAnalysis | undefined>(
    undefined
  )

  const { mutate: analyzeInvoice, isLoading: isAnalyzing } = useMutation({
    mutationFn: (invoiceId: number) => getInvoiceAnalysis(invoiceId),
    onSuccess: setAnalysis,
    onError: (error) => {
      toast.error(t('invoice.analyze.notification.error', { error }))
    },
  })

  useEffect(() => {
    if (isAnalyzing || analysis) {
      showDrawer(
        t('invoice.ai.title', {
          invoiceNumber: invoice?.invoiceNumber,
        }),
        <AISidebar steps={steps} stepIndex={stepIndex} isLoading={isAnalyzing}>
          {analysis && <Analysis analysis={analysis} />}
        </AISidebar>
      )
    }
  }, [analysis, isAnalyzing, showDrawer, t])

  const onAnalyze = useCallback(async () => {
    if (!invoice) {
      return
    }

    setStepIndex(0)
    setAnalysis(undefined)
    await analyzeInvoice(invoice!.id)
  }, [invoice, analyzeInvoice, setStepIndex, setAnalysis])

  const steps = useMemo((): string[] => {
    return stepLabels.map((label: string) => t(label))
  }, [t])

  return (
    <Button
      variant="primary"
      className="space-x-1"
      onClick={onAnalyze}
      disabled={isAnalyzing}
    >
      <SparklesIcon className="h-4 w-4" />
      <span>{t('invoice.analyze.action')}</span>
    </Button>
  )
}

const Actions = ({ invoice }: ActionsProps) => {
  return (
    <div className="flex flex-row space-x-4 items-center">
      <ActionMenu invoice={invoice} />
      <AnalysisButton invoice={invoice} />
    </div>
  )
}

export default Actions
