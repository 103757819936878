import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import classnames from 'classnames'
import { ComponentPropsWithRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

const styles = {
  error:
    'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-brand-red-dark sm:text-sm/6',
}

interface InputProps extends ComponentPropsWithRef<'input'> {
  error?: string
  description?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, error, description, ...props }, ref) => {
    return (
      <>
        <div className="relative mt-2 rounded-md">
          <input
            ref={ref}
            {...props}
            aria-invalid={error ? 'true' : undefined}
            aria-describedby={error ? 'input-error' : undefined}
            className={twMerge(
              'block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-blue-dark sm:text-sm/6',
              className,
              classnames({
                [styles.error]: !!error,
              })
            )}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                aria-hidden="true"
                className="size-5 text-red-500"
              />
            </div>
          )}
        </div>
        {description && !error && (
          <p id="email-description" className="mt-2 text-sm text-gray-500">
            {description}
          </p>
        )}
        {error && (
          <p id="input-error" className="mt-2 text-sm text-red-600">
            {error}
          </p>
        )}
      </>
    )
  }
)

Input.displayName = 'Input'

export default Input
