import { Fragment, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import BuildingPopover from '../components/BuildingPopover'
import BuildingFilterProvider, {
  useBuildingFilters,
} from '../components/BuildingPopover/BuildingFilterProvider'
import Checked from '../components/Checked'
import FilterProvider, {
  useFilters,
} from '../components/Filters/FilterProvider'
import TimePopover from '../components/Filters/TimePopover'
import ViewPopover from '../components/Filters/ViewPopover'
import NoiCard from '../components/NoiCard/NoiCard'
import { Content, Header, Page, Section, Title } from '../components/Page/Page'
import RecordDetail from '../components/RecordDetail/RecordDetail'
import Heatmap from '../components/Result/Heatmap/Heatmap'
import { useDrawer } from '../components/core/Drawer/DrawerProvider'

const Filters = () => {
  const {
    noiDefinitions,
    timeFilter,
    noiDefinition,
    absolute,
    excludeIncompleteMonths,
    setTimeFilter,
    setNoiDefinition,
    setAbsolute,
    setExcludeIncompleteMonths,
  } = useFilters()

  return (
    <div className="flex flex-col gap-y-4 justify-end sm:flex-row sm:gap-x-4">
      <BuildingPopover mode="portfolio" />
      <TimePopover timeFilter={timeFilter} setTimeFilter={setTimeFilter} />
      <ViewPopover
        noiDefinitions={noiDefinitions}
        noiDefinition={noiDefinition}
        setNoiDefinition={setNoiDefinition}
        absolute={absolute}
        setAbsolute={setAbsolute}
        excludeIncompleteMonths={excludeIncompleteMonths}
        setExcludeIncompleteMonths={setExcludeIncompleteMonths}
      />
    </div>
  )
}

const Result = () => {
  const { t } = useTranslation(['dashboard'])
  const { showDrawer, open: drawerOpen } = useDrawer()

  const {
    timeFilter,
    noiDefinition,
    absolute,
    excludeIncompleteMonths,
    buildingFilters,
  } = useFilters()
  const { portfolio } = useBuildingFilters()

  const heatmapRef = useRef()

  const onRecordUpdate = () => {
    heatmapRef.current?.refreshSelectedRow()
  }

  useEffect(() => {
    if (!drawerOpen) {
      heatmapRef.current?.clearSelectedRow()
    }
  }, [drawerOpen])

  const showRecord = useCallback(
    (record) => {
      showDrawer(
        record.text,
        <RecordDetail record={record} onUpdate={onRecordUpdate} />
      )
    },
    [showDrawer]
  )

  return (
    <Checked right="result:read">
      <Page>
        <Header actions={<Filters />}>{t('overview.title')}</Header>
        <Section id="overview">
          <Title>{t('overview.overview.title')}</Title>
          <Content>
            <NoiCard />
          </Content>
        </Section>
        <Fragment>
          {!!portfolio && !!timeFilter && !!noiDefinition && (
            <Heatmap
              ref={heatmapRef}
              noiDefinition={noiDefinition}
              buildingFilters={buildingFilters}
              timeFilter={timeFilter}
              absolute={absolute}
              excludeIncompleteMonths={excludeIncompleteMonths}
              onRecordSelected={showRecord}
            />
          )}
        </Fragment>
      </Page>
    </Checked>
  )
}

const FilterableResult = () => {
  return (
    <FilterProvider>
      <BuildingFilterProvider>
        <Result />
      </BuildingFilterProvider>
    </FilterProvider>
  )
}

export default FilterableResult
