import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useNumberOptions from '../../hooks/useNumberOptions'
import { getGridValueFormatter } from '../../utils/format'
import Page from '../Page/LegacyPage'
import AgGridReact, {
  defaultGridOptions,
  defaultTheme,
} from '../core/AgGrid/AgGrid'

const ZeroProof = forwardRef(({ data }, gridRef) => {
  const { t } = useTranslation(['dashboard'])

  const numberOptions = useNumberOptions()

  const gridOptions = useMemo(
    () => ({
      ...defaultGridOptions,
      autoSizeStrategy: {
        type: 'fitCellContents',
      },
      columnDefs: [
        {
          headerName: 'Building',
          width: 50,
          field: 'buildingReference',
        },
        {
          headerName: 'Year',
          width: 25,
          field: 'year',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
        },
        {
          headerName: 'Operating income',
          width: 150,
          field: 'operating_income',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter(
            'operating_income',
            numberOptions
          ),
        },
        {
          headerName: 'Operating expenses',
          width: 150,
          field: 'operating_expense',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter(
            'operating_expense',
            numberOptions
          ),
        },
        {
          headerName: 'Financial income',
          width: 250,
          field: 'financial_income',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter(
            'financial_income',
            numberOptions
          ),
        },
        {
          headerName: 'Financial expenses',
          width: 250,
          field: 'financial_expense',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter(
            'financial_expense',
            numberOptions
          ),
        },
        {
          headerName: 'Result',
          width: 50,
          field: 'result',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter('result', numberOptions),
        },
        {
          headerName: 'Assets change',
          width: 50,
          field: 'asset',
          valueFormatter: getGridValueFormatter('asset', numberOptions),
        },
        {
          headerName: 'Liabilities change',
          width: 50,
          field: 'liability',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter('liability', numberOptions),
        },
        {
          headerName: 'Net balance increase',
          width: 50,
          field: 'netBalanceIncrease',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter(
            'netBalanceIncrease',
            numberOptions
          ),
        },
        {
          headerName: 'Sum check',
          width: 50,
          field: 'sumCheck',
          cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
          valueFormatter: getGridValueFormatter('sumCheck', numberOptions),
        },
      ],
    }),
    [data, numberOptions]
  )

  return (
    <>
      <Page.Section.Title>{t('zeroProof.title')}</Page.Section.Title>
      <Page.Section.Content className="ag-theme-alpine">
        <AgGridReact
          theme={defaultTheme}
          domLayout="autoHeight"
          gridOptions={gridOptions}
          ref={gridRef}
          rowClass="c-grid-row"
          rowData={data}
        />
      </Page.Section.Content>
    </>
  )
})

ZeroProof.propTypes = {
  data: PropTypes.array.isRequired,
}

ZeroProof.displayName = 'ZeroProof'

export default ZeroProof
