import {
  AllCommunityModule,
  GridOptions,
  ModuleRegistry,
  themeQuartz,
} from 'ag-grid-community'
import {
  ClientSideRowModelModule,
  ExcelExportModule,
  LicenseManager,
  RichSelectModule,
  RowGroupingModule,
  ServerSideRowModelApiModule,
  ServerSideRowModelModule,
  TreeDataModule,
} from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'

import './AgGrid.css'

ModuleRegistry.registerModules([
  AllCommunityModule,
  RowGroupingModule,
  TreeDataModule,
  ClientSideRowModelModule,
  RichSelectModule,
  ServerSideRowModelModule,
  ServerSideRowModelApiModule,
  TreeDataModule,
  ExcelExportModule,
])

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY)

export const defaultTheme = themeQuartz.withParams({
  fontFamily: 'Inter',
  fontSize: 14,
  accentColor: '#1f9a41',
  wrapperBorder: false,
  headerColumnResizeHandleColor: 'oklch(0.928 0.006 264.531)',
  borderColor: 'oklch(0.928 0.006 264.531)',
})

export const defaultGridOptions: GridOptions = {
  // Make rows slightly taller than default
  rowHeight: 56,
  headerHeight: 56,

  autoSizeStrategy: {
    type: 'fitGridWidth',
  },

  selectionColumnDef: {
    width: 20,
  },
}

export default AgGridReact
