import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Invoice from '../components/Invoice/Invoice'
import { useModal } from '../components/core/Modal/ModalProvider'
import { getInvoice } from '../loaders/vendors'

const useInvoiceModal = (queryParam: string) => {
  const { showModal, open } = useModal()
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation(['dashboard'])

  // Show the invoice modal when the vendor invoice id is in the search params
  const invoiceId = useMemo(() => {
    const vendorInvoiceId = searchParams.get(queryParam)
    return vendorInvoiceId ? parseInt(vendorInvoiceId) : null
  }, [searchParams])

  const { data: invoice } = useQuery({
    enabled: !!invoiceId,
    queryKey: ['invoice', invoiceId],
    queryFn: () => getInvoice(Number(invoiceId)),
  })

  useEffect(() => {
    if (invoice) {
      showModal(<Invoice invoice={invoice} />, {
        title: t('invoice.title', {
          invoiceNumber: invoice.invoiceNumber,
        }),
      })
    }
  }, [invoice, showModal])

  useEffect(() => {
    if (!open) {
      searchParams.delete('vendorInvoiceId')
      setSearchParams(searchParams)
    }
  }, [open])
}

export default useInvoiceModal
