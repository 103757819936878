import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Card from '../components/Card/Card'
import Checked from '../components/Checked'
import { Content, Header, Page, Section, Title } from '../components/Page/Page'
import Button from '../components/core/Button/Button'
import {
  Form,
  FormField,
  FormFieldGrid,
  FormLabel,
  FormSection,
  FormValue,
} from '../components/core/Form/Form'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '../components/core/Listbox'
import { Switch, SwitchGroup, SwitchLabel } from '../components/core/Switch'
import { useFormSubmitCallback } from '../hooks/api'
import useSettings from '../hooks/useSettings'
import { updateSettings } from '../loaders/user'

const NUMBER_LOCALES = ['en-US'] as const

type NumberLocale = (typeof NUMBER_LOCALES)[number]

interface Settings {
  numberLocale?: NumberLocale | null
  notifications?: {
    invoiceAnalysis?: boolean
  }
}

interface NumberLocaleFieldProps {
  settings: Settings
  onChange: (numberLocale: NumberLocale | null) => void
}

const NumberLocaleField = ({ settings, onChange }: NumberLocaleFieldProps) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <div>
      <Listbox value={settings.numberLocale} onChange={onChange}>
        {({ open }) => (
          <>
            <div className="relative">
              <ListboxButton>
                {t(
                  `profile.settings.numberLocales.${settings.numberLocale || 'system'}`
                )}
              </ListboxButton>
              <ListboxOptions isOpen={open}>
                {[null, ...NUMBER_LOCALES].map((locale) => (
                  <ListboxOption key={locale} value={locale}>
                    {t(`profile.settings.numberLocales.${locale || 'system'}`)}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </>
        )}
      </Listbox>
      <p className="mt-2 text-sm text-gray-500">
        {t(`profile.settings.field.numberLocale.description`)}
      </p>
    </div>
  )
}

const Profile = () => {
  const { t } = useTranslation(['dashboard'])
  const [settings, setSettings] = useState<Settings>({
    numberLocale: null,
  })

  const { isLoading, settings: loadedSettings } = useSettings()
  useEffect(() => {
    if (loadedSettings) {
      setSettings(loadedSettings)
    }
  }, [loadedSettings])

  const onSubmit = useFormSubmitCallback(updateSettings, settings, {
    successKey: 'profile.notification.success',
    errorKey: 'profile.notification.error',
  })

  if (isLoading) {
    return null
  }

  return (
    <Checked right="user:self:write">
      <Page>
        <Header>
          <span>{t('profile.title')}</span>
        </Header>
        <Section id="settings">
          <Title>{t('profile.settings.title')}</Title>
          <Content>
            <Card>
              <Form onSubmit={onSubmit}>
                <FormSection>
                  <FormFieldGrid>
                    <FormField columns={6}>
                      <FormLabel htmlFor="numberLocale">
                        {t('profile.settings.field.numberLocale.title')}
                      </FormLabel>
                      <FormValue>
                        <NumberLocaleField
                          settings={settings}
                          onChange={(value) =>
                            setSettings((prev) => ({
                              ...prev,
                              numberLocale: value,
                            }))
                          }
                        />
                      </FormValue>
                    </FormField>

                    <FormField columns={6}>
                      <FormLabel htmlFor="numberLocale">
                        {t('profile.settings.field.notifications.title')}
                      </FormLabel>
                      <FormValue>
                        <SwitchGroup as="div" className="flex justify-left">
                          <Switch
                            enabled={
                              settings.notifications?.invoiceAnalysis ?? false
                            }
                            onChange={(checked: boolean) =>
                              setSettings((prev) => ({
                                ...prev,
                                notifications: {
                                  ...prev.notifications,
                                  invoiceAnalysis: checked,
                                },
                              }))
                            }
                          ></Switch>
                          <SwitchLabel as="span" className="ml-3 text-sm">
                            {t(
                              'profile.settings.field.notifications.invoiceAnalysis'
                            )}
                          </SwitchLabel>
                        </SwitchGroup>
                      </FormValue>
                    </FormField>
                  </FormFieldGrid>
                </FormSection>
                <div className="mt-6 flex justify-end gap-x-4">
                  <Button variant="primary" type="submit">
                    {t('form.save', { ns: 'translation' })}
                  </Button>
                </div>
              </Form>
            </Card>
          </Content>
        </Section>
      </Page>
    </Checked>
  )
}

export default Profile
