import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import useAuth from '../../hooks/useAuth'
import { getAttributes } from '../../loaders/attributes'
import { getNoiDefinitions } from '../../loaders/noiDefinitions'

const FilterContext = createContext()

export function useFilters() {
  return useContext(FilterContext)
}

const FilterProvider = ({ children }) => {
  const { user, isAuthenticated } = useAuth()

  const [timeFilter, setTimeFilter] = useState(null)
  const [noiDefinition, setNoiDefinition] = useState(null)

  const [absolute, setAbsolute] = useState(true)
  const [excludeIncompleteMonths, setExcludeIncompleteMonths] = useState(true)
  const [buildingFilters, setBuildingFilters] = useState({})

  const { data: noiDefinitions } = useQuery({
    queryKey: ['noiDefinitions', user?.userId],
    queryFn: getNoiDefinitions,
    enabled: isAuthenticated,
  })
  const { data: attributes } = useQuery({
    queryKey: ['attributes', user?.userId],
    queryFn: getAttributes,
    enabled: isAuthenticated,
  })

  // Set the first noi definition if none selected
  useEffect(() => {
    if (noiDefinitions && noiDefinitions.length && !noiDefinition) {
      setNoiDefinition(noiDefinitions[0])
    }
  }, [noiDefinitions, noiDefinition])

  // Set the initial filters if none selected
  useEffect(() => {
    if (attributes && attributes.length && _.isEmpty(buildingFilters)) {
      const initialFilters = attributes.reduce((acc, curr) => {
        acc[curr.id] = curr.options[0]
        return acc
      }, {})

      setBuildingFilters(initialFilters)
    }
  }, [attributes, buildingFilters])

  const value = useMemo(
    () => ({
      attributes,
      noiDefinitions,
      timeFilter,
      noiDefinition,
      absolute,
      excludeIncompleteMonths,
      buildingFilters,
      setTimeFilter,
      setNoiDefinition,
      setAbsolute,
      setExcludeIncompleteMonths,
      setBuildingFilters,
    }),
    [
      attributes,
      noiDefinitions,
      timeFilter,
      noiDefinition,
      absolute,
      excludeIncompleteMonths,
      buildingFilters,
    ]
  )

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}

FilterProvider.propTypes = {
  children: PropTypes.node,
}

export default FilterProvider
