import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'

import useNumberOptions from '../../hooks/useNumberOptions'
import Page from '../Page/LegacyPage'
import AgGridReact, {
  defaultGridOptions,
  defaultTheme,
} from '../core/AgGrid/AgGrid'
import {
  AccountNumber,
  ApplicableDate,
  Budget,
  BuildingReference,
  Underwriting,
} from './columns/'

const getColumnDefs = (numberOptions) => {
  return [
    BuildingReference,
    ApplicableDate,
    AccountNumber,
    Budget(numberOptions),
    Underwriting(numberOptions),
  ]
}

const Grid = forwardRef(({ internalBenchmarks }, gridRef) => {
  const numberOptions = useNumberOptions()

  const gridOptions = useMemo(() => {
    return {
      ...defaultGridOptions,
      columnDefs: getColumnDefs(numberOptions),
      rowSelection: {
        mode: 'multiRow',
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
    }
  }, [numberOptions])

  return (
    <>
      <Page.Section.Content className="ag-theme-alpine">
        <AgGridReact
          theme={defaultTheme}
          domLayout="autoHeight"
          gridOptions={gridOptions}
          rowData={internalBenchmarks}
          ref={gridRef}
        />
      </Page.Section.Content>
    </>
  )
})

Grid.propTypes = {
  internalBenchmarks: PropTypes.array.isRequired,
}

Grid.displayName = 'Grid'

export default Grid
