import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { getOverview } from '../../loaders/overview'
import { useBuildingFilters } from '../BuildingPopover/BuildingFilterProvider'
import Card from '../Card/Card'
import { useFilters } from '../Filters/FilterProvider'
import KPIs from './KPIs'
import NoiChart, { Overview } from './NoiChart'

const NoiCard = () => {
  const {
    noiDefinition,
    buildingFilters,
    timeFilter,
    absolute,
    excludeIncompleteMonths,
  } = useFilters()
  const { portfolio } = useBuildingFilters()

  const portfolioId = useMemo(() => portfolio?.portfolioId, [portfolio])
  const noiDefinitionId = useMemo(
    () => noiDefinition?.noiDefinitionId,
    [noiDefinition]
  )

  const { data, isLoading: isLoading } = useQuery({
    enabled:
      !!portfolio && !!noiDefinition && !!buildingFilters && !!timeFilter,
    queryKey: [
      'overview',
      portfolioId,
      noiDefinitionId,
      buildingFilters,
      timeFilter,
      absolute,
      excludeIncompleteMonths,
    ],
    queryFn: () =>
      getOverview(
        portfolioId!,
        noiDefinitionId,
        buildingFilters,
        timeFilter,
        absolute,
        excludeIncompleteMonths
      ),
  })

  const overview = data as Overview

  const { actualObservations, budgetObservations } = useMemo(() => {
    return {
      actualObservations: overview?.timelines?.actual ?? [],
      budgetObservations: overview?.timelines?.budget ?? [],
    }
  }, [overview])

  return (
    <Card className="flex flex-col sm:flex-row items-center h-80 divide-y sm:divide-y-0 sm:divide-x">
      <div className="pr-6 w-full h-full">
        {!isLoading && data && (
          <NoiChart
            observations={actualObservations}
            budgetObservations={budgetObservations}
          />
        )}
      </div>
      <div className="pl-10 pr-10 w-min-full sm:w-min-1/2 h-full">
        {!isLoading && data && <KPIs overview={data} />}
      </div>
    </Card>
  )
}

export default NoiCard
