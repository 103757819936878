import MarkdownElement, { MarkdownToJSX } from 'markdown-to-jsx'
import { ComponentPropsWithoutRef, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Reference } from '../../../models'

export const RefLink = ({ text, href }: { text: string; href: string }) => {
  return (
    <Link
      to={href}
      className="inline-block rounded-full px-2 py-0.5 font-semibold bg-gray-200 text-gray-700 hover:bg-gray-300"
    >
      {text}
    </Link>
  )
}

const options = {
  overrides: {
    span: {
      component: 'span',
      props: {
        className: 'text-sm',
      },
    },
    strong: {
      component: 'span',
      props: {
        className: 'font-semibold',
      },
    },
    RefLink: {
      component: RefLink,
    },
  },
} as MarkdownToJSX.Options

const processReferences = (
  text: string,
  references?: Reference[],
  hrefFunc?: (reference: Reference) => string | undefined
) => {
  if (!references) {
    return text
  }

  // Replace e.g. {invoice:123} with <RefLink text="Invoice 123" href="invoice/123" />
  return text.replace(/{(\w+:\d+)}/g, (match, id) => {
    const reference = references.find((r) => r.id === id)
    if (!reference) {
      return match
    }

    const href = hrefFunc?.(reference)
    if (!href) {
      return reference.text
    }

    return `<RefLink text="${reference.text}" href="${href}" />`
  })
}

interface MarkdownProps {
  references?: Reference[]
  onClick?: (reference: Reference) => void
  hrefFunc?: (reference: Reference) => string | undefined
}

const Markdown = ({
  children,
  references,
  hrefFunc,
}: ComponentPropsWithoutRef<typeof MarkdownElement> & MarkdownProps) => {
  const text = useMemo(
    () => processReferences(children, references, hrefFunc),
    [children, references, hrefFunc]
  )

  return <MarkdownElement options={options}>{text}</MarkdownElement>
}

export default Markdown
