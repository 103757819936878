import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import BuildingPopover from '../../components/BuildingPopover'
import BuildingFilterProvider, {
  useBuildingFilters,
} from '../../components/BuildingPopover/BuildingFilterProvider'
import { GridCard } from '../../components/Card/Card'
import Checked from '../../components/Checked'
import { Header, Page, Section } from '../../components/Page/Page'
import TimeRangeFilterProvider, {
  useTimeRangeFilters,
} from '../../components/TimeRangePopover/TimeRangeFilterProvider'
import TimeRangePopover from '../../components/TimeRangePopover/TimeRangePopover'
import useInvoiceModal from '../../hooks/useInvoiceModal'
import { Building } from '../../models'
import PnlTable from './PnlTable'

const Filters = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { buildings, setBuilding } = useBuildingFilters()
  const { setTimeRange } = useTimeRangeFilters()

  useEffect(() => {
    const buildingId = searchParams.get('buildingId')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    if (buildingId) {
      const queryBuildingId = parseInt(buildingId)
      const filterBuilding =
        buildings?.find((b) => b.id === queryBuildingId) ?? null

      if (filterBuilding) {
        setBuilding(filterBuilding)
      }
    }

    if (from && to) {
      setTimeRange(moment.utc(from).toDate(), moment.utc(to).toDate())
    }
  }, [searchParams, buildings])

  const onBuildingChange = (building?: Building) => {
    setBuilding(building)
    if (building) {
      searchParams.set('buildingId', building.id.toString())
    } else {
      searchParams.delete('buildingId')
    }
    setSearchParams(searchParams)
  }

  const onTimeRangeChange = (fromDate: Date | null, toDate: Date | null) => {
    if (fromDate && toDate) {
      searchParams.set('from', moment.utc(fromDate).format('YYYY-MM-DD'))
      searchParams.set('to', moment.utc(toDate).format('YYYY-MM-DD'))
    } else {
      searchParams.delete('from')
      searchParams.delete('to')
    }
    setSearchParams(searchParams)
  }

  return (
    <div className="flex flex-row gap-x-4 justify-end mb-4">
      <BuildingPopover onChange={onBuildingChange} />
      <TimeRangePopover defaultToLast={true} onChange={onTimeRangeChange} />
    </div>
  )
}

const Review = () => {
  const { t } = useTranslation(['dashboard'])

  useInvoiceModal('vendorInvoiceId')

  return (
    <BuildingFilterProvider>
      <TimeRangeFilterProvider>
        <Checked right="result:read">
          <Page>
            <Header subtitle={t('review.subtitle') ?? ''}>
              {t('review.title')}
            </Header>
            <Section>
              <Filters />
              <GridCard>
                <PnlTable />
              </GridCard>
            </Section>
          </Page>
        </Checked>
      </TimeRangeFilterProvider>
    </BuildingFilterProvider>
  )
}

export default Review
