import {
  ArrowUturnLeftIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/24/outline'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Building, Portfolio } from '../../models'
import Spinner from '../Spinner'
import Button from '../core/Button/Button'
import Select from '../core/Form/Select'
import Popover, { PopoverSection, PopoverSections } from '../core/Popover'
import { useBuildingFilters } from './BuildingFilterProvider'

interface ToolbarProps {
  onReset?: () => void
}

const Toolbar = ({ onReset }: ToolbarProps) => {
  if (!onReset) {
    return null
  }

  return (
    <div className="flex flex-row gap-8 items-center justify-end mt-6">
      <Button
        variant="transparent"
        size="sm"
        className="flex flex-row gap-1"
        onClick={onReset}
      >
        <ArrowUturnLeftIcon className="h-4 w-4" />
      </Button>
    </div>
  )
}
interface BuildingPopoverProps {
  onChange?: (building?: Building, portfolio?: Portfolio) => void
  mode?: 'portfolio' | 'building'
  required?: boolean
}

const BuildingPopover = ({
  onChange,
  mode = 'building',
  required = true,
}: BuildingPopoverProps) => {
  const { t } = useTranslation(['dashboard'])

  const [label, setLabel] = useState('')
  const {
    buildings,
    building,
    setBuilding,
    portfolios,
    portfolio,
    setPortfolio,
  } = useBuildingFilters()

  // Set the label if the portfolio changes
  useEffect(() => {
    if (mode === 'portfolio' && portfolio) {
      setLabel(portfolio.name)
    }
  }, [portfolio])

  // Set the label if the portfolio changes
  useEffect(() => {
    if (mode === 'building') {
      setLabel(building?.buildingReference ?? t('filter.building.all'))
    }
  }, [buildings, building])

  // Set the intial building if required
  useEffect(() => {
    if (!required) {
      return
    }

    if (mode === 'portfolio' && portfolios) {
      setPortfolio?.(portfolios?.[0])
    } else if (mode === 'building' && buildings) {
      setBuilding?.(buildings?.[0])
    }
  }, [required, mode, portfolios, buildings])

  const portfolioOptions = useMemo(() => {
    const options = []
    if (!required) {
      options.push({
        id: 0,
        name: t('filter.portfolio.all'),
      })
    }

    if (portfolios) {
      options.push(
        ...portfolios.map((portfolio: Portfolio) => ({
          id: portfolio.portfolioId,
          name: portfolio.name,
        }))
      )
    }

    return options
  }, [portfolios, required])

  const buildingOptions = useMemo(() => {
    const options = []
    if (!required) {
      options.push({
        id: 0,
        name: t('filter.building.all'),
      })
    }

    if (buildings) {
      options.push(
        ...buildings.map((building) => ({
          id: building.id,
          name: building.buildingReference,
        }))
      )
    }
    return options
  }, [buildings, required])

  const onPortfolioChange = useCallback(
    (portfolioId: number) => {
      if (mode !== 'portfolio') {
        return
      }

      const portfolio = portfolios?.find(
        (p) => p.portfolioId === portfolioId
      ) as Portfolio

      setPortfolio?.(portfolio)
      onChange?.(undefined, portfolio)
    },
    [portfolios, setPortfolio]
  )

  const onBuildingChange = useCallback(
    (id: number) => {
      if (mode !== 'building') {
        return
      }

      const building = buildings?.find((b) => b.id === id) as Building

      setBuilding?.(building)
      onChange?.(building, portfolio)
    },
    [buildings, setBuilding]
  )

  const onReset = useCallback(() => {
    if (mode === 'portfolio') {
      setPortfolio?.(undefined)
    } else if (mode === 'building') {
      setBuilding?.(undefined)
    }
    onChange?.(undefined, undefined)
  }, [mode, setBuilding, setPortfolio])

  return (
    <Popover
      text={label}
      icon={<BuildingOfficeIcon aria-hidden="true" className="h-5 w-5" />}
      hasValue={!required && (!!portfolio || !!building)}
    >
      <PopoverSections>
        {mode === 'portfolio' && (
          <PopoverSection>
            {portfolios ? (
              <>
                <div className="block font-medium text-gray-900 mb-2">
                  {t('filter.portfolio.label')}
                </div>
                <Select
                  options={portfolioOptions}
                  selectedOption={
                    portfolio
                      ? {
                          id: portfolio.portfolioId,
                          name: portfolio.name,
                        }
                      : undefined
                  }
                  onChange={(option) => onPortfolioChange(option.id as number)}
                />
              </>
            ) : (
              <Spinner />
            )}
            <Toolbar onReset={!required ? onReset : undefined} />
          </PopoverSection>
        )}
        {mode === 'building' && (
          <PopoverSection>
            {buildings ? (
              <>
                <div className="block font-medium text-gray-900 mb-2">
                  {t('filter.building.label')}
                </div>
                <Select
                  options={buildingOptions}
                  selectedOption={
                    building
                      ? {
                          id: building.id,
                          name: building.buildingReference,
                        }
                      : undefined
                  }
                  onChange={(option) => onBuildingChange(option.id as number)}
                />
              </>
            ) : (
              <Spinner />
            )}
            <Toolbar onReset={!required ? onReset : undefined} />
          </PopoverSection>
        )}
      </PopoverSections>
    </Popover>
  )
}

export default BuildingPopover
