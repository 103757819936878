import { ComponentPropsWithoutRef } from 'react'

import Logo from '../Logo'
import Toaster from '../ToastProvider'
import * as Typography from '../core/Typography'

interface ExternalPageProps extends ComponentPropsWithoutRef<'div'> {
  title: string
}

const ExternalPage = ({ children, title }: ExternalPageProps) => {
  return (
    <>
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center p-6">
        <Logo className="h-8 w-auto" altText="Plinto" />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Typography.H2 className="mt-6 text-center">{title}</Typography.H2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">{children}</div>
      </div>
      <Toaster />
    </>
  )
}

export default ExternalPage
