import { VendorInvoice } from '../../models'
import Fields from './Fields'
import Lines from './Lines'

interface InvoiceContentProps {
  invoice: VendorInvoice
}

const Invoice = ({ invoice }: InvoiceContentProps) => {
  return (
    <div className="flex-1 px-4">
      <div className="mt-6">
        <Fields invoice={invoice} />
      </div>
      <div className="mt-16">
        <Lines invoice={invoice} />
      </div>
    </div>
  )
}

export default Invoice
