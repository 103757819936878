import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CategoryLabel from '../../components/CategoryLabel/CategoryLabel'
import Button from '../../components/core/Button/Button'
import { VendorInvoice } from '../../models'
import { formatDate } from '../../utils/format'

interface FieldProps extends ComponentPropsWithoutRef<'div'> {
  label: string
}

const Field = ({ label, children }: FieldProps) => {
  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm/6 font-medium text-gray-900">{label}</dt>
      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
        {children}
      </dd>
    </div>
  )
}

interface FieldsProps {
  invoice: VendorInvoice
}

const Fields = ({ invoice }: FieldsProps) => {
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()
  return (
    <dl className="divide-y divide-gray-100">
      <Field label={t('invoice.purchaseDate')}>
        {formatDate(invoice.purchaseDate)}
      </Field>
      {invoice.dueDate && (
        <Field label={t('invoice.dueDate')}>
          {formatDate(invoice.dueDate)}
        </Field>
      )}
      {invoice.periodStartDate && invoice.periodEndDate && (
        <Field label={t('invoice.period')}>
          {`${formatDate(invoice.periodStartDate)} - ${formatDate(
            invoice.periodEndDate
          )}`}
        </Field>
      )}
      {invoice.utilityMeterId && (
        <Field label={t('invoice.utilityMeter')}>
          {invoice.utilityMeterId}
        </Field>
      )}
      <Field label={t('invoice.currency')}>{invoice.currency}</Field>
      <Field label={t('invoice.vendor')}>
        <Button
          variant="soft"
          size="xs"
          className="rounded-full bg-brand-blue-light"
          onClick={() => navigate(`/vendors`)}
        >
          <span>{invoice?.vendor?.name}</span>
        </Button>
      </Field>

      {invoice.category && (
        <Field label={t('invoice.category')}>
          <CategoryLabel category={invoice.category} />
        </Field>
      )}
    </dl>
  )
}

export default Fields
