import { ChevronRightIcon, SparklesIcon } from '@heroicons/react/24/outline'
import { ExpandedChangedEvent, ICellRendererParams } from 'ag-grid-community'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'

import AISidebar, { AISection } from '../../components/AISidebar/AISidebar'
import Button from '../../components/core/Button/Button'
import { useDrawer } from '../../components/core/Drawer/DrawerProvider'
import { useFetchCallback } from '../../hooks/api'
import { getPnlExplanation } from '../../loaders/pnl'
import { Building, Category, Reference } from '../../models'
import usePnlRow from './usePnlRow'

const STEP_LABELS: string[] = [
  'review.explain.ai.step.lookup',
  'review.explain.ai.step.review',
  'review.explain.ai.step.summary',
]

const useExplain = (
  category: Category,
  hasChildren: boolean,
  {
    building,
    fromDate,
    toDate,
  }: {
    building: Building
    fromDate: Date | null
    toDate: Date | null
  }
) => {
  const { t } = useTranslation(['dashboard'])
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const { showDrawer } = useDrawer()

  // Hooks to explain the category
  const {
    isLoading: isExplaining,
    data: explanation,
    fetch: onExplain,
  } = useFetchCallback(
    () =>
      getPnlExplanation(building!.id, category!.id, {
        from: fromDate,
        to: toDate,
        includeChildCategories: hasChildren,
      }),
    [building, fromDate, category, toDate]
  )

  const steps = useMemo((): string[] => {
    return STEP_LABELS.map((label: string) => t(label))
  }, [t])

  const buildReferenceHref = useCallback(
    (reference: Reference) => {
      if (reference.refType === 'invoice') {
        searchParams.set(
          'vendorInvoiceId',
          reference.data.vendorInvoiceId.toString()
        )
        return `${location.pathname}?${searchParams.toString()}`
      }
    },
    [searchParams, location]
  )

  useEffect(() => {
    if (isExplaining || explanation) {
      showDrawer(
        t('review.explain.title'),
        <AISidebar steps={steps} stepIndex={0} isLoading={isExplaining}>
          {explanation && (
            <AISection
              title={t('review.explain.section.explanation')}
              text={explanation.explanation}
              references={explanation.references}
              hrefFunc={buildReferenceHref}
            />
          )}
        </AISidebar>
      )
    }
  }, [explanation, isExplaining])

  return onExplain
}

const Indentation = ({ indentation }: { indentation: number }) => {
  return (
    <>
      {[...Array(indentation)].map((_, i) => (
        <div key={i} className="size-4" />
      ))}
    </>
  )
}

const ExpandButton = ({
  expanded,
  onClick,
  isVisible,
}: {
  expanded: boolean
  onClick: () => void
  isVisible: boolean
}) => {
  if (!isVisible) return <div className="size-6" />

  return (
    <button
      className="size-6 hover:bg-gray-200 rounded-md inline-flex items-center justify-center"
      onClick={onClick}
    >
      <ChevronRightIcon
        className={classNames(
          'size-4 cursor-pointer inline-block transition-transform',
          expanded ? 'rotate-90' : 'rotate-0'
        )}
      />
    </button>
  )
}

const ExplainButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="transparent"
      size="xs"
      className="text-brand-600"
      onClick={onClick}
    >
      <SparklesIcon className="size-4" />
    </Button>
  )
}

const CategoryCellRenderer = ({
  node,
  value,
  context,
}: ICellRendererParams) => {
  const { t } = useTranslation(['dashboard'])

  const [showExplainButton, setShowExplainButton] = useState(false)
  const [expanded, setExpanded] = useState(node.expanded)

  const pnlRow = usePnlRow(node)
  const category = useMemo(() => pnlRow?.category, [pnlRow])
  const indentation = node.uiLevel ?? 0
  const hasChildren = node.group ?? false

  const onExplain = useExplain(category, hasChildren, context)

  // Hook up expansion of child rows
  useEffect(() => {
    const listener = (event: ExpandedChangedEvent) =>
      setExpanded(event.node.expanded)
    node.addEventListener('expandedChanged', listener)

    return () => {
      node.removeEventListener('expandedChanged', listener)
    }
  }, [])

  const onClick = useCallback(() => node.setExpanded(!node.expanded), [node])

  return (
    <div
      className="w-full h-full flex flex-row items-center justify-between"
      onMouseOver={() => setShowExplainButton(true)}
      onMouseOut={() => setShowExplainButton(false)}
    >
      <div className="flex flex-row items-center">
        {value && (
          <>
            <Indentation indentation={indentation} />
            <ExpandButton
              expanded={expanded}
              onClick={onClick}
              isVisible={hasChildren}
            />
          </>
        )}
        <span className="ml-1">{value ?? t('review.totalRow')}</span>
      </div>
      {showExplainButton && category && <ExplainButton onClick={onExplain} />}
    </div>
  )
}

export default CategoryCellRenderer
