import Logo from '../../Logo'

const name = 'Plinto'

const CompanyLogo = () => {
  return (
    <div className="flex flex-shrink-0 items-center">
      <Logo className="block h-5 w-auto lg:hidden" altText={name} />
      <Logo className="hidden h-5 w-auto lg:block" altText={name} />
    </div>
  )
}

export default CompanyLogo
