import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { createContext, useContext, useMemo, useState } from 'react'

import useAuth from '../../hooks/useAuth'
import { getBuildings } from '../../loaders/buildings'
import { getAllPortfolios } from '../../loaders/portfolios'
import { Building } from '../../models'
import { Portfolio } from '../../models'

type FilterContextType = {
  buildings?: Building[]
  portfolios?: Portfolio[]
  building?: Building
  portfolio?: Portfolio
  setPortfolio: (portfolio?: Portfolio) => void
  setBuilding: (building?: Building) => void
}
const BuildingFilterContext = createContext<FilterContextType>({
  buildings: undefined,
  portfolios: undefined,
  building: undefined,
  portfolio: undefined,
  setPortfolio: () => {},
  setBuilding: () => {},
})

export function useBuildingFilters() {
  return useContext(BuildingFilterContext)
}

const BuildingFilterProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { user, isAuthenticated } = useAuth()

  const [portfolio, setPortfolio] = useState<Portfolio | undefined>(undefined)
  const [building, setBuilding] = useState<Building | undefined>(undefined)

  const { data: portfolios } = useQuery({
    queryKey: ['portfolios', user?.userId],
    queryFn: getAllPortfolios,
    enabled: isAuthenticated,
  })

  const { data: buildings } = useQuery({
    queryKey: ['buildings', user?.userId],
    queryFn: getBuildings,
    enabled: isAuthenticated,
  })

  const value = useMemo(
    () => ({
      buildings,
      portfolios,
      building,
      portfolio,
      setPortfolio,
      setBuilding,
    }),
    [buildings, portfolios, building, portfolio, setPortfolio, setBuilding]
  )

  return (
    <BuildingFilterContext.Provider value={value}>
      {children}
    </BuildingFilterContext.Provider>
  )
}

BuildingFilterProvider.propTypes = {
  children: PropTypes.node,
}

export default BuildingFilterProvider
