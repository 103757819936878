import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { ComponentPropsWithoutRef } from 'react'

interface DisclosureBoxProps extends ComponentPropsWithoutRef<'div'> {
  title: string
}

const DisclosureBox = ({ title, children, className }: DisclosureBoxProps) => {
  return (
    <Disclosure
      as="div"
      className={classNames('ease-in duration-300', className)}
    >
      <DisclosureButton className="group flex w-full items-center justify-between font-semibold">
        <span>{title}</span>
        <ChevronDownIcon className="size-4" />
      </DisclosureButton>
      <DisclosurePanel className="mt-2 flex flex-col gap-4">
        {children}
      </DisclosurePanel>
    </Disclosure>
  )
}

export default DisclosureBox
