import { useQuery } from '@tanstack/react-query'
import { createContext, useContext, useMemo, useState } from 'react'

import useAuth from '../../hooks/useAuth'
import { getChildrenByStandard } from '../../loaders/categories'
import { Category } from '../../models'

type FilterContextType = {
  categories?: Category[]
  category?: Category
  setCategory: (category?: Category) => void
  includeChildCategories: boolean
  setIncludeChildCategories: (includeChildCategories: boolean) => void
}
const CategoryFilterContext = createContext<FilterContextType>({
  categories: undefined,
  category: undefined,
  setCategory: () => {},
  includeChildCategories: false,
  setIncludeChildCategories: () => {},
})

export function useCategoryFilters() {
  return useContext(CategoryFilterContext)
}

const useCategories = () => {
  const { user, isAuthenticated } = useAuth()

  const { data: categories, isLoading } = useQuery({
    queryKey: ['categories', user?.userId],
    queryFn: () => getChildrenByStandard('root__operating__expense'),
    enabled: isAuthenticated,
  })

  return {
    categories,
    isLoading,
  }
}

const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [includeChildCategories, setIncludeChildCategories] = useState(false)
  const [category, setCategory] = useState<Category | undefined>(undefined)
  const { categories } = useCategories()

  const value = useMemo(
    () => ({
      categories,
      category,
      setCategory,
      includeChildCategories,
      setIncludeChildCategories,
    }),
    [
      categories,
      category,
      setCategory,
      includeChildCategories,
      setIncludeChildCategories,
    ]
  )

  return (
    <CategoryFilterContext.Provider value={value}>
      {children}
    </CategoryFilterContext.Provider>
  )
}

export default FilterProvider
