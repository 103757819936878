import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'

import { updateAccount } from '../../loaders/accounts'
import {
  BusinessOrigin,
  Concerning,
  Entry,
  Specifics,
  Type,
} from '../Grid/columns/dynamic/categories'
import Page from '../Page/LegacyPage'
import AgGridReact, {
  defaultGridOptions,
  defaultTheme,
} from '../core/AgGrid/AgGrid'
import { Kind, Name, NoiDefinitions, Number } from './columns'

const Grid = forwardRef((data, gridRef) => {
  const { accounts, noiDefinitions, categories } = data

  const gridOptions = useMemo(
    () => ({
      ...defaultGridOptions,
      defaultColDef: {
        editable: true,
        suppressMovable: true,
      },
      columnDefs: [
        Name,
        NoiDefinitions(noiDefinitions),
        BusinessOrigin(categories),
        Type(categories),
        Entry(categories),
        Concerning(categories),
        Specifics(categories),
        Number,
        Kind,
      ],
      onCellValueChanged: async function ({ data }) {
        const { accountId, categoryId } = data

        const noiDefinitionIds = data.noiDefinitions.map(
          (noiDefinition) => noiDefinition.noiDefinitionId
        )

        await updateAccount({ accountId, categoryId, noiDefinitionIds })
      },
      rowSelection: {
        mode: 'multiRow',
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
    }),
    [categories, accounts, noiDefinitions]
  )

  return (
    <Page.Section.Content className="ag-theme-alpine">
      <AgGridReact
        theme={defaultTheme}
        domLayout="autoHeight"
        gridOptions={gridOptions}
        ref={gridRef}
        rowClass="c-grid-row"
        rowData={accounts}
      />
    </Page.Section.Content>
  )
})

Grid.propTypes = {
  accounts: PropTypes.array.isRequired,
  noiDefinitions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
}

Grid.displayName = 'Grid'

export default Grid
