import moment from 'moment'

import { PnlExplanation, PnlRow } from '../models'
import httpClient from '../utils/httpClient'
import { fromJsonApi } from '../utils/jsonapi'

export interface PnlParams {
  from?: Date | null
  to?: Date | null
  includeChildCategories?: boolean
}

export const getPnl = async (buildingId: number, { from, to }: PnlParams) => {
  const response = await httpClient.get(`/pnl/building/${buildingId}`, {
    params: {
      include: 'category,parentCategories,childCategories',
      from: from ? moment.utc(from).format('YYYY-MM-DD') : undefined,
      to: to ? moment.utc(to).format('YYYY-MM-DD') : undefined,
    },
  })

  return fromJsonApi<PnlRow[]>(response.data)
}

export const getPnlExplanation = async (
  buildingId: number,
  categoryId: number,
  { from, to, includeChildCategories }: PnlParams
) => {
  const response = await httpClient.get(
    `/pnl/building/${buildingId}/category/${categoryId}/explanation`,
    {
      params: {
        from: from ? moment.utc(from).format('YYYY-MM-DD') : undefined,
        to: to ? moment.utc(to).format('YYYY-MM-DD') : undefined,
        includeChildCategories,
        include: 'references',
      },
    }
  )

  return fromJsonApi<PnlExplanation>(response.data)
}
