import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

type FilterContextType = {
  fromDate: Date | null
  toDate: Date | null
  setTimeRange: (fromDate: Date | null, toDate: Date | null) => void
}
const TimeRangeFilterContext = createContext<FilterContextType>({
  fromDate: null,
  toDate: null,
  setTimeRange: () => {},
})

export function useTimeRangeFilters() {
  return useContext(TimeRangeFilterContext)
}
const TimeRangeFilterProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)

  const setTimeRange = useCallback(
    (fromDate: Date | null, toDate: Date | null) => {
      setFromDate(fromDate)
      setToDate(toDate)
    },
    []
  )

  const value = useMemo(
    () => ({
      fromDate,
      toDate,
      setTimeRange,
    }),
    [fromDate, toDate, setTimeRange]
  )

  return (
    <TimeRangeFilterContext.Provider value={value}>
      {children}
    </TimeRangeFilterContext.Provider>
  )
}

export default TimeRangeFilterProvider
