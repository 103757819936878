import { ArrowUturnLeftIcon, TagIcon } from '@heroicons/react/24/outline'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Category } from '../../models'
import Spinner from '../Spinner'
import Button from '../core/Button/Button'
import Select from '../core/Form/Select'
import Popover, { PopoverSection, PopoverSections } from '../core/Popover'
import { Switch, SwitchGroup, SwitchLabel } from '../core/Switch'
import { useCategoryFilters } from './CategoryFilterProvider'

interface ToolbarProps {
  onReset?: () => void
}

const Toolbar = ({ onReset }: ToolbarProps) => {
  if (!onReset) {
    return null
  }

  return (
    <div className="flex flex-row gap-8 items-center justify-end">
      <Button
        variant="transparent"
        size="sm"
        className="flex flex-row gap-1"
        onClick={onReset}
      >
        <ArrowUturnLeftIcon className="h-4 w-4" />
      </Button>
    </div>
  )
}
interface CategoryPopoverProps {
  onChange?: (category?: Category, includeChildCategories?: boolean) => void
}

const CategoryPopover = ({ onChange }: CategoryPopoverProps) => {
  const { t } = useTranslation(['dashboard'])

  const [label, setLabel] = useState('')
  const {
    categories,
    category,
    setCategory,
    includeChildCategories,
    setIncludeChildCategories,
  } = useCategoryFilters()

  // Set the label if the portfolio changes
  useEffect(() => {
    setLabel(category?.fullName ?? t('filter.category.all'))
  }, [category])

  const options = useMemo(() => {
    const options = [
      {
        id: 0,
        name: t('filter.category.all'),
      },
    ]

    if (categories) {
      options.push(
        ...categories.map((category) => ({
          id: category.id,
          name: category.fullName,
        }))
      )
    }

    return options
  }, [categories])

  const onCategoryChange = useCallback(
    (categoryId: number) => {
      const category = categories?.find((c) => c.id === categoryId) as Category
      setCategory?.(category)
      onChange?.(category, includeChildCategories)
    },
    [categories, includeChildCategories]
  )

  const onincludeChildCategoriesChange = useCallback(
    (includeChildCategories: boolean) => {
      setIncludeChildCategories?.(includeChildCategories)
      onChange?.(category, includeChildCategories)
    },
    [category, includeChildCategories]
  )

  const onReset = useCallback(() => {
    setCategory?.(undefined)
    onChange?.(undefined, includeChildCategories)
  }, [setCategory, includeChildCategories])

  return (
    <Popover
      text={label}
      icon={<TagIcon aria-hidden="true" className="h-5 w-5" />}
      hasValue={!!category}
    >
      <PopoverSections>
        <PopoverSection>
          {categories ? (
            <>
              <div className="block font-medium text-gray-900 mb-2">
                {t('filter.category.label')}
              </div>
              <Select
                options={options}
                selectedOption={
                  category
                    ? {
                        id: category.id,
                        name: category.fullName,
                      }
                    : undefined
                }
                onChange={(option) => onCategoryChange(option.id as number)}
              />
            </>
          ) : (
            <Spinner />
          )}
          <SwitchGroup as="div" className="flex justify-left mt-4">
            <Switch
              enabled={includeChildCategories}
              onChange={onincludeChildCategoriesChange}
            ></Switch>
            <SwitchLabel as="span" className="ml-3 text-sm">
              {t('filter.category.includeChildCategories')}
            </SwitchLabel>
          </SwitchGroup>
          <Toolbar onReset={onReset} />
        </PopoverSection>
      </PopoverSections>
    </Popover>
  )
}

export default CategoryPopover
