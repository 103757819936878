import { ClockIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { TimeFilter } from '../../../models'
import Popover, { PopoverSection, PopoverSections } from '../../core/Popover'
import TimeSelector from './TimeSelector/TimeSelector'
import TimeSelectorProvider from './TimeSelector/TimeSelectorProvider'

interface TimePopoverProps {
  timeFilter?: TimeFilter
  setTimeFilter: (timeFilter: TimeFilter) => void
}

const TimePopover = ({ timeFilter, setTimeFilter }: TimePopoverProps) => {
  const [label, setLabel] = useState('')

  return (
    <TimeSelectorProvider
      selected={timeFilter}
      onChange={(newFilter, formattedText) => {
        setTimeFilter(newFilter)
        setLabel(formattedText)
      }}
    >
      <Popover
        text={label}
        icon={<ClockIcon aria-hidden="true" className="h-5 w-5" />}
      >
        <PopoverSections>
          <PopoverSection>
            <TimeSelector />
          </PopoverSection>
        </PopoverSections>
      </Popover>
    </TimeSelectorProvider>
  )
}

export default TimePopover
