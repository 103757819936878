import { useMutation } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { updateSettings } from '../../loaders/apps'
import Page from '../Page/LegacyPage'
import AgGridReact, {
  defaultGridOptions,
  defaultTheme,
} from '../core/AgGrid/AgGrid'
import Button from '../core/Button/Button'

export default function BusinessCentralSettings() {
  const { t } = useTranslation(['dashboard'])

  const [companyToBuildingIds, setCompanyToBuildingIds] = useState({})

  const navigate = useNavigate()
  const updateSettingsMutation = useMutation({
    mutationFn: updateSettings,
  })

  const onSubmit = useCallback(async (event) => {
    event.preventDefault()

    updateSettingsMutation.mutate(
      {
        appId: 'business_central',
        settings: {
          companyToBuildingIds,
        },
      },
      {
        onSuccess: () => {
          toast.success(t('apps.notification.success'))
          navigate('/setup/apps/businessCentral')
        },
        onError: (error) => {
          toast.error(
            t('apps.notification.error', {
              error: error.toString(),
            })
          )
        },
      }
    )
  })

  const gridOptions = useMemo(
    () => ({
      ...defaultGridOptions,
      columnDefs: [
        {
          headerName: 'Company ID',
          field: 'companyId',
          editable: true,
        },
        {
          headerName: 'Building ID',
          field: 'buildingId',
          editable: true,
        },
      ],
      onCellValueChanged: async function ({ data }) {
        const { buildingId, companyId } = data

        if (buildingId !== 'Missing' && companyId !== 'Missing') {
          companyToBuildingIds[companyId] = buildingId

          setCompanyToBuildingIds(companyToBuildingIds)
        }
      },
    }),
    []
  )

  return (
    <>
      <div>
        <Page.Section.Content className="ag-theme-alpine">
          <AgGridReact
            theme={defaultTheme}
            gridOptions={gridOptions}
            rowData={[
              { companyId: 'Missing', buildingId: 'Missing' },
              { companyId: 'Missing', buildingId: 'Missing' },
            ]}
            domLayout="autoHeight"
          />
        </Page.Section.Content>
      </div>

      <div>
        <Button className="ml-3" onClick={onSubmit} variant="primary">
          {t('apps.businessCentral.saveSettings')}
        </Button>
      </div>
    </>
  )
}
