import { useMutation } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'

import { updateBuilding } from '../../loaders/buildings'
import Page from '../Page/LegacyPage'
import AgGridReact, {
  defaultGridOptions,
  defaultTheme,
} from '../core/AgGrid/AgGrid'
import {
  BuildingReference,
  ConstructionYear,
  Display,
  Floors,
  Portfolio,
  PrimaryUsage,
  Size,
  Units,
} from './columns/'

const Grid = forwardRef(({ rowData }, gridRef) => {
  const mutation = useMutation({
    mutationFn: updateBuilding,
  })

  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        })
      }
    },
  })

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      })
    }
  }, [])

  const gridOptions = useMemo(
    () => ({
      ...defaultGridOptions,
      defaultColDef: {
        filter: true,
        editable: true,
      },
      columnDefs: [
        BuildingReference,
        Units,
        Size,
        PrimaryUsage,
        Floors,
        ConstructionYear,
        Display,
        Portfolio,
      ],
      onCellValueChanged: ({ data }) => {
        const {
          portfolio: { name },
        } = data

        data.portfolioName = name

        mutation.mutate(data)
      },
      rowSelection: {
        mode: 'multiRow',
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
    }),
    [mutation]
  )

  return (
    <Page.Section.Content className="ag-theme-alpine">
      <AgGridReact
        theme={defaultTheme}
        domLayout="autoHeight"
        gridOptions={gridOptions}
        onFirstDataRendered={onFirstDataRendered}
        ref={gridRef}
        rowClass="c-grid-row"
        rowData={rowData}
      />
    </Page.Section.Content>
  )
})

Grid.propTypes = {
  rowData: PropTypes.array,
}

Grid.displayName = 'Grid'

export default Grid
