import classnames from 'classnames'
import React, { ComponentPropsWithoutRef } from 'react'

interface HeaderProps extends ComponentPropsWithoutRef<'header'> {
  as?: React.ElementType
  className?: string
}

export const Header = ({
  as: Component = 'h1',
  children,
  ...props
}: HeaderProps) => <Component {...props}>{children}</Component>

export const H1 = ({ className, ...props }: ComponentPropsWithoutRef<'h1'>) => (
  <Header
    as="h1"
    className={classnames(
      'text-3xl font-semibold leading-tight tracking-tight text-brand-black',
      className
    )}
    {...props}
  />
)

export const H2 = ({ className, ...props }: ComponentPropsWithoutRef<'h2'>) => (
  <Header
    as="h2"
    className={classnames(
      'text-2xl font-semibold tracking-tight text-brand-black',
      className
    )}
    {...props}
  />
)

export const H3 = ({ className, ...props }: ComponentPropsWithoutRef<'h3'>) => (
  <Header
    as="h3"
    className={classnames('text-xl leading-7', className)}
    {...props}
  />
)

export const H4 = ({ className, ...props }: ComponentPropsWithoutRef<'h4'>) => (
  <Header
    as="h4"
    className={classnames('text-lg leading-7', className)}
    {...props}
  />
)
