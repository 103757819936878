import { Category } from '../models'
import httpClient from '../utils/httpClient'
import { fromJsonApi } from '../utils/jsonapi'

interface GetCategoriesOptions {
  topCategoryStandard?: string
  includeTopCategory?: boolean
  includeNull?: boolean
}

export const getCategories = async ({
  topCategoryStandard = 'root',
  includeTopCategory = true,
  includeNull = false,
}: GetCategoriesOptions = {}) => {
  const response = await httpClient.get('/category/all', {
    params: {
      topCategoryStandard,
      includeTopCategory,
      includeNull,
    },
  })

  return response.data
}

export const getChildrenByStandard = async (standard: string) => {
  const response = await httpClient.get(
    `/category/standard/${standard}/children`
  )

  return fromJsonApi<Category[]>(response.data)
}
