import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Card from '../../components/Card/Card'
import Checked from '../../components/Checked'
import InvoiceContent from '../../components/Invoice/Invoice'
import { Header, Page, Section } from '../../components/Page/Page'
import { getInvoice } from '../../loaders/vendors'
import Actions from './Actions'

export const Invoice = () => {
  const { t } = useTranslation(['dashboard'])
  const { invoiceId } = useParams()

  const { data: invoice, isLoading } = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: () => getInvoice(Number(invoiceId)),
  })

  return (
    <Checked right="vendor:read">
      <Page>
        <Header
          subtitle={t('invoice.subtitle', {
            vendor: invoice?.vendor?.name,
          })}
          actions={<Actions invoice={invoice} />}
        >
          {t('invoice.title', {
            invoiceNumber: invoice?.invoiceNumber,
          })}
        </Header>
        {!isLoading && invoice && (
          <Section>
            <Card>
              <InvoiceContent invoice={invoice} />
            </Card>
          </Section>
        )}
      </Page>
    </Checked>
  )
}

export default Invoice
