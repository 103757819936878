import {
  ArrowUturnLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../core/Button/Button'

export type PeriodType = 'month' | 'quarter'

const PERIOD_COUNT: Record<PeriodType, number> = {
  month: 12,
  quarter: 4,
}

interface TimePickerProps {
  periodType: PeriodType
  date: Date | null
  onChange?: (date: Date | null) => void
  adjustTo?: 'start' | 'end'
}

const TimePicker = ({
  periodType,
  date,
  onChange,
  adjustTo,
}: TimePickerProps) => {
  const { t } = useTranslation(['dashboard'])

  const initialPeriod = useMemo(() => {
    if (periodType === 'month') {
      return moment.utc(date).month()
    } else if (periodType === 'quarter') {
      return moment.utc(date).quarter() - 1
    }
    return null
  }, [date, periodType])

  const [year, setYear] = useState<number>(moment.utc(date ?? undefined).year())
  const [period, setPeriod] = useState<number | null>(initialPeriod)

  // If date is reset, reset period
  useEffect(() => {
    if (date === null) {
      setPeriod(null)
    }
  }, [date])

  // Call onChange with the new date
  useEffect(() => {
    let newDate: Date | null = null
    if (period != null) {
      const periodDate = moment.utc().year(year)
      if (periodType === 'month') {
        periodDate.month(period)
      } else if (periodType === 'quarter') {
        periodDate.quarter(period + 1)
      }

      if (adjustTo === 'start') {
        periodDate.startOf(periodType)
      } else {
        periodDate.endOf(periodType)
      }

      if (periodDate.isValid()) {
        newDate = periodDate.toDate()
      }
    }
    onChange?.(newDate)
  }, [year, period, periodType])

  const isCurrent = useCallback(
    (index: number) => {
      return period === index
    },
    [period]
  )

  const getText = useCallback(
    (index: number) => {
      switch (periodType) {
        case 'month':
          return moment.utc().month(index).format('MMM')
        case 'quarter':
          return t(`filter.time.quarter.q${index + 1}`)
      }
    },
    [periodType]
  )

  return (
    <div className="w-full flex flex-col gap-4 min-w-48">
      <div className="flex items-center gap-4 justify-center">
        <Button
          variant="transparent"
          size="sm"
          onClick={() => setYear(year - 1)}
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </Button>
        <span>{year}</span>
        <Button
          variant="transparent"
          size="sm"
          onClick={() => setYear(year + 1)}
        >
          <ChevronRightIcon className="h-4 w-4" />
        </Button>
      </div>
      <div className="grid grid-cols-4 gap-2">
        {[...Array(PERIOD_COUNT[periodType])].map((_, index) => (
          <div
            key={index}
            onClick={() => setPeriod(index)}
            className={classNames(
              'w-full h-10 rounded-md text-center flex items-center justify-center cursor-pointer',
              {
                'bg-brand-600 text-white': isCurrent(index),
                'bg-gray-100 text-gray-500': !isCurrent(index),
              }
            )}
          >
            {getText(index)}
          </div>
        ))}
      </div>
    </div>
  )
}

interface TimeRangePickerProps {
  periodType?: 'month' | 'quarter'
  fromDate: Date | null
  toDate: Date | null
  onChange?: (fromDate: Date | null, toDate: Date | null) => void
}

const TimeRangePicker = ({
  periodType: initialType = 'month',
  fromDate: initialFromDate,
  toDate: initialToDate,
  onChange,
}: TimeRangePickerProps) => {
  const [type] = useState<PeriodType>(initialType)
  const [fromDate, setFromDate] = useState<Date | null>(initialFromDate)
  const [toDate, setToDate] = useState<Date | null>(initialToDate)

  useEffect(() => {
    onChange?.(fromDate, toDate)
  }, [fromDate, toDate])

  const onReset = useCallback(() => {
    setFromDate(null)
    setToDate(null)
  }, [])

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row gap-8 items-center">
        <TimePicker
          periodType={type}
          date={fromDate}
          onChange={(date) => setFromDate(date)}
          adjustTo={'start'}
        />
        <TimePicker
          periodType={type}
          date={toDate}
          onChange={(date) => setToDate(date)}
          adjustTo={'end'}
        />
      </div>
      <div className="flex flex-row gap-8 items-center justify-end">
        <Button
          variant="transparent"
          size="sm"
          className="flex flex-row gap-1"
          onClick={onReset}
        >
          <ArrowUturnLeftIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

export default TimeRangePicker
