import { IRowNode } from 'ag-grid-community'
import _ from 'lodash'
import { useMemo } from 'react'

// This hook is designed to return the correct PnL row for the given node.
// the reason we need this is because for grouped rows, the row has to be retrived
// from the "Other" row among the child rows.
export default function usePnlRow(node: IRowNode) {
  return useMemo(() => {
    const childRows = node.childrenAfterGroup ?? []

    if (node.group) {
      // Find the "other" child row, which corresponds to the group category
      const otherChildRow = _.minBy(
        childRows,
        (childRow) => childRow?.data?.parentCategories.length
      )

      return otherChildRow?.data
    } else {
      // If the row is not a group, return the row data directly
      return node.data
    }
  }, [node])
}
