import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface CardProps extends ComponentPropsWithoutRef<'div'> {
  as?: React.ElementType
  className?: string
}

const Card = ({
  as: Component = 'div',
  children,
  className,
  ...props
}: CardProps) => {
  return (
    <Component
      className="overflow-hidden rounded-lg bg-white ring-1 ring-gray-200"
      {...props}
    >
      <div className={twMerge('px-4 py-5 sm:p-6', className)}>{children}</div>
    </Component>
  )
}

export const GridCard = (props: CardProps) => {
  return <Card className="px-0 py-0 sm:p-0" {...props} />
}

export default Card
