import { useMutation } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'

import { updateNoiDefinition } from '../../loaders/noiDefinitions'
import AgGridReact, {
  defaultGridOptions,
  defaultTheme,
} from '../core/AgGrid/AgGrid'
import { Name } from './columns'

const Grid = forwardRef(({ noiDefinitions, refetch }, gridRef) => {
  const updateMutation = useMutation({
    mutationFn: updateNoiDefinition,
    onSuccess: () => {
      return refetch().then(() => {
        gridRef.current.api.refreshCells()
      })
    },
  })

  const gridOptions = useMemo(
    () => ({
      ...defaultGridOptions,
      defaultColDef: {
        suppressMovable: true,
        editable: true,
      },
      columnDefs: [Name],
      onCellValueChanged: async function ({ data }) {
        updateMutation.mutate(data)
      },
      rowSelection: {
        mode: 'multiRow',
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
    }),
    [noiDefinitions]
  )

  return (
    <AgGridReact
      theme={defaultTheme}
      domLayout="autoHeight"
      gridOptions={gridOptions}
      // onFirstDataRendered={onFirstDataRendered}
      ref={gridRef}
      rowClass="c-grid-row"
      rowData={noiDefinitions}
    />
  )
})

Grid.propTypes = {
  noiDefinitions: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
}

Grid.displayName = 'Grid'

export default Grid
