import { ColDef } from 'ag-grid-community'
import { ValueFormatterParams } from 'ag-grid-community'
import { useCallback } from 'react'

import useNumberOptions from '../../../hooks/useNumberOptions'
import { formatNumber } from '../../../utils/format'

export const NUMBER_CELL_CLASS = 'ag-right-aligned-cell'
export const NUMBER_HEADER_CLASS =
  'bg-white font-semibold ag-right-aligned-header'

export const useNumberColumn = (colDef: ColDef) => {
  const numberOptions = useNumberOptions()

  const valueFormatter = useCallback(
    (params: ValueFormatterParams) => {
      return (
        formatNumber(params.value, {
          ...numberOptions,
          decimals: 0,
        }) ?? ''
      )
    },
    [numberOptions]
  )

  return {
    ...colDef,
    cellClass: NUMBER_CELL_CLASS,
    headerClass: NUMBER_HEADER_CLASS,
    valueFormatter,
  }
}
