import { ICellRendererParams } from 'ag-grid-community'
import moment from 'moment'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import FormattedNumber from '../../components/core/FormattedNumber/FormattedNumber'
import usePnlRow from './usePnlRow'

const InvoicedCellRenderer = ({
  value,
  node,
  context,
}: ICellRendererParams) => {
  const { building, fromDate, toDate } = context

  const pnlRow = usePnlRow(node)
  const category = useMemo(() => pnlRow?.category, [pnlRow])

  const params = useMemo(() => {
    const params = new URLSearchParams()

    if (building) {
      params.set('buildingId', String(building.id))
    }

    if (category) {
      params.set('categoryId', String(category.id))
    }

    if (fromDate) {
      params.set('from', moment.utc(fromDate).format('YYYY-MM-DD'))
    }

    if (toDate) {
      params.set('to', moment.utc(toDate).format('YYYY-MM-DD'))
    }

    // If the node is a group, include child categories when clicked
    if (node.group) {
      params.set('includeChildCategories', 'true')
    }

    return params.toString()
  }, [building, category, fromDate, toDate, node])

  return (
    <NavLink
      to={`/vendors/invoices?${params.toString()}`}
      className="cursor-pointer hover:underline decoration-dotted"
    >
      <FormattedNumber value={value} defaultValue={''} decimals={0} />
    </NavLink>
  )
}

export default InvoicedCellRenderer
