import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'

import { updateRecord } from '../../loaders/records'
import {
  BusinessOrigin,
  Concerning,
  Entry,
  Specifics,
  Type,
} from '../Grid/columns/dynamic/categories'
import Page from '../Page/LegacyPage'
import AgGridReact, {
  defaultGridOptions,
  defaultTheme,
} from '../core/AgGrid/AgGrid'
import {
  AccountName,
  AccountNumber,
  Amount,
  BuildingReference,
  ExternalRecordId,
  ProcessingDate,
  RecordId,
  SyncComment,
  Text,
  TextLocale,
} from './columns'

const Grid = forwardRef(({ data, buildings }, gridRef) => {
  const { categories, records } = data

  const gridOptions = useMemo(
    () => ({
      ...defaultGridOptions,
      autoSizeStrategy: {
        type: 'fitCellContents',
      },
      defaultColDef: {
        editable: true,
        suppressMovable: true,
      },
      columnDefs: [
        RecordId,
        ExternalRecordId,
        ProcessingDate,
        Text,
        SyncComment,
        BuildingReference(buildings),
        BusinessOrigin(categories),
        Type(categories),
        Entry(categories),
        Concerning(categories),
        Specifics(categories),
        AccountNumber,
        AccountName,
        TextLocale,
        Amount,
      ],
      onCellValueChanged: async function ({ data }) {
        await updateRecord(data)
      },
      rowSelection: {
        mode: 'multiRow',
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
    }),
    [categories]
  )

  return (
    <Page.Section.Content className="ag-theme-alpine">
      <AgGridReact
        theme={defaultTheme}
        domLayout="autoHeight"
        gridOptions={gridOptions}
        ref={gridRef}
        rowClass="c-grid-row"
        rowData={records}
      />
    </Page.Section.Content>
  )
})

Grid.propTypes = {
  data: PropTypes.shape({
    accounts: PropTypes.array,
    categories: PropTypes.array,
    records: PropTypes.array,
  }),
  buildings: PropTypes.array,
}

Grid.displayName = 'Grid'

export default Grid
